

import router from '@/router'
import { defineComponent,ref,watch} from 'vue'

export default defineComponent({
    props:{
        servicos : {
            required: true,
        }
    },
    emits: ['servicoEspecifico','goToParams'],  
    setup(prop,{emit}){

        const servicos = ref(prop.servicos)
        const servicoEspecifico = ref()

        watch(() => prop.servicos, () => {
            servicos.value = prop.servicos
        })

        const classFornecedor =  (fornecedor) => {
            const fornecedorClasses = {
                1: 'distribuidor',
                2: 'outros',
                3: 'originais',
            };

            return fornecedorClasses[fornecedor] || '';
        }

        const setServicoEspecifico = (servico) => {
            servicoEspecifico.value = servico
            emit("servicoEspecifico", servicoEspecifico.value);  
        }

        const goToParams = (routeName, servico) => {
            router.push({
            name: routeName,
            params: {
            codServico: servico.codServico,
            },
        })
        }

        return {
            servicos,
            classFornecedor,
            setServicoEspecifico,
            goToParams
        }
    }
    
})
