
import {defineComponent, onMounted , ref,computed} from 'vue'
import { Modal } from "bootstrap";
import CrudService, { getDescricao } from "@/services/CrudService";
import useAlert from "@/composables/Alert";
import { gerarXLSX,blob}  from '../composables/importExcelTemplate';
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";


export interface CodigosDescricao {
  codPersonalizacao: number;
  nomePersonalizacao: string;
}

export default defineComponent({
    emits: ['updateServices','downloadExel'],
    props: {
        lengthServicoHabilitado : {
            type: Number,
            required: true
        }
    },
    setup(props,{emit}){
        const descriptionOfModalCodes = ref()
        const searchDescription = ref()
        const descriptionCodes = ref()
        const concessionariaSelected = ref(Number(window.localStorage.getItem("filtroConcessionaria")));
        const { showTimeAlert } = useAlert();
        const store = useStore()
        const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
        const  fornecedor = ref(`Fornecedor (1 = ${distribuidor} / 2 = Outros)`);
        const uploadRef = ref();
        const modalExport = ref();
        const usuarioLogado = computed(() => store.getters.usuarioLogado);

        store.watch(() => store.getters.concessionariaSelectedInfo, () => {
            concessionariaSelected.value = store.getters.concessionariaSelectedInfo.codConcessionaria
        }, {deep: true});
       
        function showConfirmAlert(title, icon = "success", html = "", confirmButtonText = "Ok" , positon = 'top-end'){						
            const Toast = Swal.mixin({
                position: positon,
                html: html,
                showConfirmButton: true,
                customClass: {
                    confirmButton: "bg-none",
                    content: 'custom-size'
                },
                confirmButtonText,
                focusConfirm: false,
            });

            Toast.fire({
                icon,
                title,
            });
        };


        const renderModal = () => {
            const refModal = document.getElementById("import-modal");
            if (refModal) {
                document.getElementById("layout_content")?.appendChild(refModal);
            }
            modalExport.value = new Modal(refModal);

            //modal códigos descrição

            const modalDescricao = document.getElementById("modalDescricao");
            if (modalDescricao) {
                document.getElementById("layout_content")?.appendChild(modalDescricao);
            }

            descriptionOfModalCodes.value = new Modal(modalDescricao);
        }

        const showModalDescription = () => {
            descriptionOfModalCodes.value.show();
            searchDescription.value = "";
        };

        const filterItemsDescription = computed(() => {
            if (searchDescription.value) {
                return descriptionCodes.value?.filter((item: CodigosDescricao) => {
                    return (
                        item.nomePersonalizacao
                        .toLowerCase()
                        .includes(searchDescription.value.toLowerCase()) ||
                        item.codPersonalizacao.toString().includes(searchDescription.value)
                    );
                });
            } else {
                return descriptionCodes.value;
            }
        });

        async function getDescricoes() {
            if (descriptionCodes.value) return;
            try {
                const response = await getDescricao(concessionariaSelected.value);
                descriptionCodes.value = response.descricao.sort((a, b) => {
                if (a.codPersonalizacao > b.codPersonalizacao) return 1;
                if (a.codPersonalizacao < b.codPersonalizacao) return -1;
                return 0;
                });

            } catch (error) {
                console.log(error);
                showTimeAlert("Não foi possível completar a solicitação", "error");
            }
        }

        const avisoConfirmacaoImport = (NamefileImport) => {
            const useSwal = Swal.fire({
                title: "Confirmação de importação da planilha",
                text:
                "Todos os itens da planilha substituirão os itens já existentes. Você tem certeza de que deseja fazer isso?",
                showCancelButton: true,
                confirmButtonText: "Importar Planilha",
                cancelButtonText: "Cancelar",
                buttonsStyling: false,
                customClass: {
                confirmButton: "btn btn-flex background_btn_1_opct text-white",
                cancelButton: "btn btn-light me-3",
                },
                reverseButtons: true,
            });

            const divMessage = document.querySelector(".swal2-title");
            divMessage?.setAttribute("style", "display: block");
            const span = document.createElement("span");
            span.classList.add("name-file-upload");
            span.innerHTML = `<br><br> ${NamefileImport}`;
            // span.appendChild(textSpan);
            divMessage?.appendChild(span);
            return useSwal;
        };

        function handleSuccess(response, file, fileList) {
            const formData = new FormData();
            formData.append("file", file[0].raw);
            if (!response.name.includes(".xlsx")) {
                showTimeAlert("Formato de arquivo invalido", "error");
                uploadRef.value.clearFiles(); // limpar o campo do upload
                return;
            }
            avisoConfirmacaoImport(response.name).then((result) => {
                if (result.isConfirmed) {
                 useUpload(formData);
                } else {
                 uploadRef.value.clearFiles(); // limpar o campo do upload
                }
            });
         }

         const useUpload = (formData) => {
            document.body.classList.add("page-loading");               
            CrudService.importExcel(concessionariaSelected.value, usuarioLogado.value.codUsuario,formData)              
                .then((r) => {
                document.body.classList.remove("page-loading");

                if (r.length > 0) {
                    uploadRef.value.clearFiles()
                    const error = r?.map((erro) => {
                    return `Error  na linha ${erro.row} : ${erro.errors} <br>`;
                    });

                    showConfirmAlert("", "error", error.join(" "), "OK", "center");
                    return;
                }

                emit('updateServices') // emit para atualizar os serviços na listagem
                modalExport.value.hide();
                uploadRef.value.clearFiles(); // limpar o campo do upload
                showTimeAlert("Planilha importada com sucesso.");    
                })
                .catch((r) => {
                    console.log(r);
                    showTimeAlert("Não foi possível completar a solicitação", "error");
                    uploadRef.value.clearFiles(); // limpar o campo do upload
                    document.body.classList.remove("page-loading");
                });
        };

        function exportarXLSX() {
            const link = document.createElement("a");
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob.value);
                link.setAttribute("href", url);
                link.setAttribute("download", "MODELO_EXPORTACAO_SERVICO.xlsx");
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }

        const downloadReportTemplate = () => {
            if(props.lengthServicoHabilitado > 1){
                emit('downloadExel')
            }else{
                gerarXLSX(fornecedor)
                exportarXLSX() 
            }            
        }
        
        onMounted(()=> {
            renderModal()
            getDescricoes()
        })

        return{
            showModalDescription,
            searchDescription,
            getDescricoes,
            filterItemsDescription,
            downloadReportTemplate,
            handleSuccess,
            uploadRef
        }
    }
})

