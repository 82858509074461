
import {onMounted, reactive, ref,watch} from "vue";
import {servicoCopy, storeServicoCopy} from "@/services/CrudService";
import {getConcessionarias} from "@/services/GeralService";
import useAlert from "@/composables/Alert";
import {hideModal} from '@/core/helpers/dom';

export default {
  name: "visualizarServicoModal",

  props: {
    codConcessionaria: {
      type: Number,
      required: true,
    }
  },

  emits: ["atualizar"],

  setup(props, { emit }) {
    onMounted(() => {
      listaConcessionarias();
    });

    const {showTimeAlert} = useAlert();
    const loading = ref(false);
    const loadingServico = ref(false);
    const optionsConcessionaria: any = ref([]);
    const servicoSelected: any = ref([]);
    const ruleFormRef = ref<null | HTMLFormElement>(null);
    const loadingButton = ref(false);
    const allService = ref(false)

    async function listaConcessionarias() {
      loading.value = true;
      optionsConcessionaria.value = await getConcessionarias();
      loading.value = false;
    }

    watch(() => allService.value, () => {
      if(allService.value){
        form.codServicos = []
      }
    })

    const optionsServicos: any = ref([]);

    async function listaServicos() {
      loadingServico.value = true;
      optionsServicos.value = await servicoCopy(form.codConcessionariaAntigo);
      loadingServico.value = false;
    }

    const form: any = reactive({
      codServicos: "",
      codConcessionariaAntigo: ""
    });

    const servicosInput = (rule: any,value: any,callback: any) => {
      if(value.length == 0 && !allService.value){
          callback(new Error('Marque um serviço pelo menos'))
          return false
      }      
      return true
    }

    const rules = ref({
      codServicos: [
        {required: true, trigger: 'blur', validator: servicosInput}
      ],
      codConcessionariaAntigo: [
        {required: true, message: 'Selecione a concessionária', trigger: 'change'}
      ]
    });

    const submitForm = () => {
      if (!ruleFormRef.value) return
      ruleFormRef.value.validate((valid) => {
        if (valid) {
          loadingButton.value = true;
          const postData = {
            codServicos: allService.value ? optionsServicos.value.map(servico => servico.codServico ) : form.codServicos,
            codConcessionariaNovo: props.codConcessionaria
          }
          storeServicoCopy(postData).then(function () {
            showTimeAlert("Serviço criado com sucesso");
          }).catch(function (error) {
            showTimeAlert("Ocorreu um erro", 'error');
          }).finally(() => {
            const elementModal = document.getElementById("copiarServicoModal");
            hideModal(elementModal);
            form.codConcessionariaAntigo = ''
            form.codServicos = []
            allService.value = false
            loadingButton.value = false;
            emit("atualizar");
          });
        } else {
          showTimeAlert("Preencha os campos", 'error');
        }
      })
    }

    return {
      loading,
      optionsConcessionaria,
      servicoSelected,
      optionsServicos,
      listaServicos,
      loadingServico,
      form,
      rules,
      submitForm,
      ruleFormRef,
      loadingButton,
      allService,
    }
  }
}
