import XLSX from "xlsx-js-style";
import { ref } from 'vue'

const blob = ref()

const servicosModelo = [
    {
      codServico: 15856,
      codConcessionaria: 555,
      codCategoria: 1,
      nome: "Aditivo sólido de Combustível",
      tipo: 2,
      valorCompra: "13,83",
      valorPeca: "14,50",
      valorMaoDeObra: "14,50",
      valorTotal: "44,40",
      valorPremiacao: "0.00",
      tempoMaoObra: "0.00",
      ordemExibicao: 1,
      corColuna: "#3CB371",
      situacao: 1,
      intServico: "CBLUNIT41010101",
      intServicoMO: null,
      codwynns: "UNIT41010101",
      unidadescaixa: 72,
      estoque: 13,
      dataestoque: "05/06/2022",
      fornecedor: "1",
      ticketmedio: 1,
      excluido: 0,
      usuarioexcluiu: null,
      codPersonalizacao: null,
      intGrupo: null,
      intSubGrupo: null,
      ultNfFaturada: null,
      ultNfDtFaturada: null,
      dataCadastro: "2023-03-22 14:26:50",
      dataAlteracao: null,
      nomeConcessionaria: "Atria Testes Dafra",
      personalizacao: null,
    },
    {
      codServico: 15857,
      codConcessionaria: 555,
      codCategoria: 1,
      nome: "Aditivo Sólido de Combustível (Novo)",
      tipo: 2,
      valorCompra: "13,83",
      valorPeca: "14,50",
      valorMaoDeObra: "14,50",
      valorTotal: "44,40",
      valorPremiacao: "0.00",
      tempoMaoObra: "0.00",
      ordemExibicao: 1,
      corColuna: "#3CB371",
      situacao: 1,
      intServico: "CBL012",
      intServicoMO: "115",
      codwynns: "UNIT41010101",
      unidadescaixa: 72,
      estoque: 0,
      dataestoque: null,
      fornecedor: "1",
      ticketmedio: 1,
      excluido: 0,
      usuarioexcluiu: null,
      codPersonalizacao: null,
      intGrupo: null,
      intSubGrupo: null,
      ultNfFaturada: null,
      ultNfDtFaturada: null,
      dataCadastro: "2023-03-22 14:26:50",
      dataAlteracao: null,
      nomeConcessionaria: "Atria Testes Dafra",
      personalizacao: null,
    },
    {
      codServico: 15858,
      codConcessionaria: 555,
      codCategoria: 1,
      nome: "Cristalização de Para-Brisas",
      tipo: 2,
      valorCompra: "16,50",
      valorPeca: "29,00",
      valorMaoDeObra: "29,00",
      valorTotal: "60,90",
      valorPremiacao: "0.00",
      tempoMaoObra: "0.00",
      ordemExibicao: 1,
      corColuna: "#3CB371",
      situacao: 1,
      intServico: "CBLUNIT42020202",
      intServicoMO: "110",
      codwynns: "UNIT42020202",
      unidadescaixa: 0,
      estoque: 28,
      dataestoque: "31/05/2022",
      fornecedor: "1",
      ticketmedio: 1,
      excluido: 0,
      usuarioexcluiu: null,
      codPersonalizacao: null,
      intGrupo: null,
      intSubGrupo: null,
      ultNfFaturada: null,
      ultNfDtFaturada: null,
      dataCadastro: "2023-03-22 14:26:50",
      dataAlteracao: null,
      nomeConcessionaria: "Atria Testes Dafra",
      personalizacao: null,
    },
];

function gerarXLSX(fornecedor) {
    const servicosToXLSX = [...servicosModelo].map((item) => {
      return {
        Serviço: item.nome,
        "Código Categoria (1 = Agregados / 2 = Alinhamento / 3 = Pneus / 4 = Baterias / 5 = Palhetas / 6 = Acessórios / 7 = Estética / 8 = Funilaria / 9 = Balanceamento / 10 = Oxi - Sanitização / 11 = Homologados / 12 = Aromatizantes)":
          item.codCategoria,
        "Código Imagem/Video/Descricao": item.codPersonalizacao,
        "Tipo (2 = Produtos / 3 = Serviços)": item.tipo,
        [fornecedor]: parseInt(item.fornecedor),
        "Contabiliza Ticket Médio (1 = Sim / 2 = Não)": item.ticketmedio,
        "Situação (1 = Habilitado / 0 = Desabilitado)": item.situacao,
        "Valor Compra": item.valorCompra
          ? parseFloat(item.valorCompra.replace(",", "."))
          : 0.0,
        "Valor Peça": item.valorPeca
          ? parseFloat(item.valorPeca.replace(",", "."))
          : 0.0,
        "Valor Mão de Obra": item.valorMaoDeObra
          ? parseFloat(item.valorMaoDeObra.replace(",", "."))
          : 0.0,
        "Tempo Mão de Obra": parseFloat(item.tempoMaoObra),
        "Valor Total": item.valorTotal
          ? parseFloat(item.valorTotal.replace(",", "."))
          : 0.0,
        "Valor Premiação": item.valorPremiacao
          ? parseFloat(item.valorPremiacao.replace(",", "."))
          : 0.0,
        "Código Peça": item.intServico,
        "Código MO": item.intServicoMO,
        "Código Forrnecedor": item.codwynns,
        "Unidades por Caixa": item.unidadescaixa ?? 0.0,
        "Estoque Atual": item.estoque ?? 0.0,
        "Data Estoque": item.dataestoque,
      };
    });

    let wb: XLSX.WorkBook = XLSX.utils.book_new();
    wb.Props = {
      Title: "Serviços",
      Subject: "Serviços",
    };

    wb.SheetNames.push("Servicos");

    const ws = XLSX.utils.json_to_sheet(
      servicosToXLSX.length == 0
        ? [
            {
              Serviço: "",
              "Código Categoria (1 = Agregados / 2 = Alinhamento / 3 = Pneus / 4 = Baterias / 5 = Palhetas / 6 = Acessórios / 7 = Estética / 8 = Funilaria / 9 = Balanceamento / 10 = Oxi - Sanitização / 11 = Homologados / 12 = Aromatizantes)":
                "",
              "Código Imagem/Video/Descricao": "",
              "Tipo (2 = Produtos / 3 = Serviços)": "",
              "Fornecedor (1 = HML / 2 = Outros)": "",
              "Contabiliza Ticket Médio (1 = Sim / 2 = Não)": "",
              "Situação (1 = Habilitado / 0 = Desabilitado)": "",
              "Valor Compra": "",
              "Valor Peça": "",
              "Valor Mão de Obra": "",
              "Tempo Mão de Obra": "",
              "Valor Total": "",
              "Valor Premiação": "",
              "Código Peça": "",
              "Código MO": "",
              "Código Forrnecedor": "",
              "Unidades por Caixa": "",
              "Estoque Atual": "",
              "Data Estoque": "",
            },
          ]
        : servicosToXLSX
    );
    const wscols = [
      { wch: 80 },
      { wch: 135 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
      { wch: 40 },
    ];
    ws["!cols"] = wscols;

    ws["A1"].s = {
      fill: {
        fgColor: { rgb: "b7b7b7" },
      },
    };
    ws["B1"].s = {
      fill: {
        fgColor: { rgb: "b7b7b7" },
      },
    };
    ws["C1"].s = {
      fill: {
        fgColor: { rgb: "b7b7b7" },
      },
    };
    ws["D1"].s = {
      fill: {
        fgColor: { rgb: "b7b7b7" },
      },
    };
    ws["E1"].s = {
      fill: {
        fgColor: { rgb: "b7b7b7" },
      },
    };
    ws["F1"].s = {
      fill: {
        fgColor: { rgb: "b7b7b7" },
      },
    };
    ws["G1"].s = {
      fill: {
        fgColor: { rgb: "b7b7b7" },
      },
    };
    ws["H1"].s = {
      fill: {
        fgColor: { rgb: "20a5e8" },
      },
    };
    ws["I1"].s = {
      fill: {
        fgColor: { rgb: "20a5e8" },
      },
    };
    for (let i = 1; i <= servicosToXLSX.length; i++) {
      let aux = i + 1;
      ws["H" + aux].z = "0.00";
      ws["I" + aux].z = "0.00";
      ws["J" + aux].z = "0.00";
      ws["K" + aux].z = "0.00";
      ws["L" + aux].z = "0.00";
      ws["M" + aux].z = "0.00";
      ws["Q" + aux].z = "0.00";
      ws["R" + aux].z = "0.00";
    }
    ws["J1"].s = {
      fill: {
        fgColor: { rgb: "20a5e8" },
      },
    };
    ws["K1"].s = {
      fill: {
        fgColor: { rgb: "20a5e8" },
      },
    };
    ws["L1"].s = {
      fill: {
        fgColor: { rgb: "20a5e8" },
      },
    };
    ws["M1"].s = {
      fill: {
        fgColor: { rgb: "20a5e8" },
      },
    };
    ws["N1"].s = {
      fill: {
        fgColor: { rgb: "f5ac55" },
      },
    };
    ws["O1"].s = {
      fill: {
        fgColor: { rgb: "f5ac55" },
      },
    };
    ws["P1"].s = {
      fill: {
        fgColor: { rgb: "28b761" },
      },
    };
    ws["Q1"].s = {
      fill: {
        fgColor: { rgb: "28b761" },
      },
    };
    ws["R1"].s = {
      fill: {
        fgColor: { rgb: "28b761" },
      },
    };
    ws["S1"].s = {
      fill: {
        fgColor: { rgb: "28b761" },
      },
    };

    wb.Sheets["Servicos"] = ws;

    function s2ab(s) {
      var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      var view = new Uint8Array(buf); //create uint8array as viewer
      for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
      return buf;
    }

    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    blob.value = new Blob([s2ab(wbout)], {
      type: "text/plain;charset=UTF-8",
    });
}

export {blob,gerarXLSX}

